/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "themes";

html,
body {}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Extra info If using a mixin for custom themes you can do something like this to get all the colors:
https://material.angular.io/guide/theming-your-components
@mixin snackbar($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary));
  $primary-text: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn));
  $warn-text: mat.get-color-from-palette(map-get($theme, warn), default-contrast);

  .style-succes {
    color: $primary-text;
    background-color: $primary;
  }
  .style-succes .mat-simple-snackbar-action  {
    color: $primary-text;
  }
  .style-error {
    color: $warn-text;
    background-color: $warn;
  }
  .style-error .mat-simple-snackbar-action {
    color: $warn-text;
  }
}
*/

.mat-simple-snackbar-action {
  color: white;
}

snack-bar-container {
  .success {
    color: white;
    background-color: green;
  }
}

.mat-mdc-snack-bar-container {
  &.message-success {
    margin-top: 60px;
    --mdc-snackbar-container-color: teal;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-snack-bar-container {
  &.message-warn {
    --mdc-snackbar-container-color: orange;
    --mat-mdc-snack-bar-button-color: black;
    --mdc-snackbar-supporting-text-color: black;
  }
}

.mat-mdc-snack-bar-container {
  &.message-fail {
    --mdc-snackbar-container-color: orangered;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.message-success {
  color: white !important;
  background-color: teal !important;
}

.message-warn {
  color: white !important;
  background-color: orange !important;
}

.message-fail {
  color: white !important;
  background-color: orangered !important;
}

div.add-image-button {
  width: 300px;
  margin-right: 0px;
  text-align: center;
  border: 1px;
  // background-color: rgb(245, 245, 245);
  border-radius: 1em;
}

button.add-image-button {
  width: 280px;
  height: 300px;
  text-align: center;
  border: 1px;
}